import React from 'react';
import '../assets/css/Style.css';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import md5 from 'md5';
import axios from 'axios';
import qs from 'qs';
import Logo from '../components/logo';
import loading from '../assets/imgs/loading.gif';

//const html_path = 'http://localhost:3000/';
//const php_path = 'http://localhost/promocaosorteio/';
const html_path = 'https://unimedsobral.epizy.com/';
const php_path = 'https://unimedsobral.epizy.com/api/';

class Sorteio extends React.Component {    
    
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            auth: true,
            premio: null,
            sorteando: false,
            escondeButton: false,
            titulo: 'INFORMATIVO!',            
            texto: <>1. Sua sorte decide quanto você ganhará de desconto.<br/><br/>
            2. O candidato pode ganhar até 30% de desconto na adesão e só poderá participar uma vez.<br/><br/>
            3. Após o sorteio será enviado o resultado via e-mail para o candidato, o consultor e setor de cadastro.<br/><br/>
            4. Para iniciar o sorteio clique no botão abaixo.<br/><br/>                        
            </>,
            descButton: 'INICIAR SORTEIO',
        }
    }

    async componentDidMount(){        
        let qs = require('qs');
        let obj = this;
        let id_vendedor = localStorage.getItem('@uniPromo/id_vendedor') ?? false;
        let data = localStorage.getItem('@uniPromo/data') ?? false;
        let token = localStorage.getItem('@uniPromo/token') ?? false;
        let id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id;

        if (!id || !id_vendedor || !data || !token || md5(id_vendedor + '-' + data) !== token) {
            window.location = html_path + 'login';
        } else {
            let formData = new FormData();
            formData.append('id', id);
            await axios({
                method: 'post',
                url: php_path + 'listar.php',
                data: formData,        
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function(response) {
                console.log(response);
                if (response.data[0] === true) {
                    if (response.data[1].adesao !== null) {
                        window.location = html_path + 'visualizarCadastro?id=' + id;
                    } else {
                        obj.setState({
                            dados: response.data[1],
                            auth: true
                        });
                    }
                } else {
                    window.location = html_path + 'home';
                }
            })
            .catch(function(response) {
                console.log(response);
            })
        }
    }

    sortear = async () => {
        
        if(this.state.spinning){
            window.location = html_path + 'home';
            return;
        }

        let descontoSorteado = Math.floor(Math.random() * 6 + 1)
        let sucesso = false;
        this.setState({
            sorteando: true,
            spinning: true,
            premio: descontoSorteado,            
        })

        let formData = new FormData();
        formData.append('id_desconto', descontoSorteado);
        formData.append('id_sorteado', qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id);
        await axios({
            method: 'post',
            url: php_path + 'salvar_sorteio.php',
            data: formData,        
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(function(response) {
            console.log(response);
            if (response.data[0] === true) {
                sucesso = true;
            } else {
                alert("Erro ao sortear valor! Por favor, atualize a página e tente novamente!");
            }
        })
        .catch(function(response) {
            console.log(response);
        })

        if (sucesso) { 
            let descontos = [
                { adesao:'5%', vitalicio:'15%'},
                { adesao:'10%', vitalicio:'15%'},
                { adesao:'15%', vitalicio:'10%'},
                { adesao:'20%', vitalicio:'10%'},
                { adesao:'25%', vitalicio:'5%'},
                { adesao:'30%', vitalicio:'5%'},
            ];
            console.log(this.state.premio)
            setTimeout(() => {
                this.setState({
                    sorteando: false,
                    titulo: 'PARABÉNS!',                    
                    texto: (
                        <>      
                        <br/>                  
                        <Typography align="center" variant="h5">
                            DESCONTO NA ADESÃO
                            <Typography variant="h2">
                                {descontos[this.state.premio-1].adesao}    
                            </Typography>
                        </Typography>                        
                        <br/>
                        <Typography align="center" variant="h6">
                            DESCONTO VITALÍCIO
                            <Typography variant="h4">
                                {descontos[this.state.premio-1].vitalicio}
                            </Typography>
                        </Typography>
                        <br/>
                        </>
                    ),
                    descButton: 'CONCLUIR'
                });
            }, 3000)
        }
    }

    render(){
        return (
            <div className="gridParent">
            {this.state.auth &&
            <Grid container justify="center">
                <Logo/>
                <Grid className="gridBody" container item xs={12} justify="center">
                    <Grid className="gridSorteio" container item xs={12} justify="center">                        
                        <Grid className="gridSorteioPrincipal" container item xs={12} justify="center">                            
                            <Grid item xs={12}>
                                {!this.state.sorteando && 
                                <>
                                    <Typography variant="h5" align="center">{this.state.titulo}</Typography>                        
                                    <Typography align="justify">
                                        {this.state.texto}
                                    </Typography>
                                    <Typography align="center">
                                        <Button
                                            className='btn2'
                                            disabled={this.state.escondeButton}
                                            onClick={() => {this.sortear()}}
                                        >
                                            {this.state.descButton}
                                        </Button>
                                    </Typography>
                                </>}
                                {this.state.sorteando &&
                                <div style={{textAlign: 'center'}}>
                                    <img src={loading} alt="this slowpoke moves"  width="320"/>
                                    <Typography align="center">
                                        <Button
                                            className='btn-cancel'                                            
                                            onClick={() => {window.location.reload() }}
                                        >
                                            CANCELAR
                                        </Button>
                                    </Typography>
                                </div>}
                            </Grid>
                            <Grid item xs={12} sm={12}>                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>                
            </Grid>
            }
            </div>
        );
    }
}

export default Sorteio;

import React from 'react';
import '../assets/css/Style.css';
import logo from '../assets/imgs/logo.png';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography, withStyles } from '@material-ui/core';
import md5 from 'md5';
import axios from 'axios';
import qs from 'qs';
import Logo from '../components/logo';

//const html_path = 'http://localhost:3000/';
//const php_path = 'http://localhost/promocaosorteio/';
const html_path = 'https://unimedsobral.epizy.com/';
const php_path = 'https://unimedsobral.epizy.com/api/';

const StyledHeadTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(220, 255, 173, 1)',
        borderWidth: '5px',
    },
}))(TableRow);

const StyledBodyTableRow = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: 'rgb(220, 255, 173, 0.5)',
      }
    },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(220, 255, 173, 1)',
        '&:hover': {
            backgroundColor: 'rgb(186, 255, 96, 1)',
        }
    },
}))(Button);

const StyledTextField = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(220, 255, 173, 1)',
    },
}))(TextField);

class Home extends React.Component {    
    
    constructor(props) {
        super(props);

        this.state = {
            sorteados: [],
            auth: false,
            page: 0,
            rowsPerPage: 8,
            emptyRows: 0,
        }
    }

    buscarSorteados(busca) {
        if (busca.length == 0 || busca.length >= 3) {
            let obj = this;
            let formData = new FormData();
            formData.append('busca', busca);
    
            axios({
                method: 'post',
                url: php_path + 'listar.php',
                data: formData,        
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            })
            .then(function (response) {
                let aux = response.data.splice(0)
                obj.setState({ 
                    sorteados: aux,
                    emptyRows: obj.state.rowsPerPage - Math.min(obj.state.rowsPerPage, aux.length - obj.state.page * obj.state.rowsPerPage)
                });
                console.log(obj.state.sorteados);
            })
            .catch(function (response) {
                console.log(response);
            });
        }

    }

    componentDidMount() {        
        let id_vendedor = localStorage.getItem('@uniPromo/id_vendedor') ?? false;
        let data = localStorage.getItem('@uniPromo/data') ?? false;
        let token = localStorage.getItem('@uniPromo/token') ?? false;
        let qs = require('qs');
        if (!id_vendedor || !data || !token || md5(id_vendedor + '-' + data) !== token) {
            window.location = html_path + 'login';
        } else {
             this.setState({ auth: true })
        }
        
        let doc = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).busca;
        this.buscarSorteados(doc ?? '');
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            emptyRows: this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.sorteados.length - newPage * this.state.rowsPerPage)
        })
    }

    render(){
        return (
            <div className="gridParent" align="center">
            {this.state.auth &&
            <Grid container justify="center">
                <Logo/>
                <Grid container justify="center" className="gridHeader">                       
                        <Grid item xs={12} sm={4}>                            
                            <div align="center" style={{marginBottom:'10px'}}>
                            <Button  className="btn" onClick={() => {window.location = html_path + 'cadastro'}}>Cadastrar candidato</Button >
                            </div>
                        </Grid>                    
                        <Grid item xs={12} sm={8}>
                            <TextField style={{width: '100%'}}
                                id="outlined-basic" label="Outlined" label="Buscar sorteado" variant="outlined" size="small" placeholder="Nome ou CPF"
                                onChange={(e) => {this.buscarSorteados(e.target.value)}} />
                        </Grid>                        
                </Grid>
                <Grid container item xs={12} justify="center" >   
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <StyledHeadTableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell>Telefone</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell></TableCell>
                                    </StyledHeadTableRow>
                                </TableHead>
                                <TableBody>
                                    {(this.state.sorteados.slice(
                                        this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage 
                                        + this.state.rowsPerPage))
                                    .map((row, i) => (
                                        <StyledBodyTableRow key={i} onClick={() => {window.location = html_path + 'visualizarCadastro?id=' + row.id_sorteado}}>
                                            <TableCell>{row.nome_cliente}</TableCell>
                                            <TableCell>{row.telefone}</TableCell>
                                            <TableCell>{row.email_cliente}</TableCell>
                                            <TableCell></TableCell>
                                        </StyledBodyTableRow>
                                    ))}
                                    {this.state.emptyRows > 0 && (
                                        <TableRow style={{ height: 45 * this.state.emptyRows }}>
                                            <TableCell colSpan={4} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={2}
                                            count={this.state.sorteados.length}
                                            rowsPerPage={this.state.rowsPerPage}
                                            rowsPerPageOptions={[]}
                                            page={this.state.page}
                                            onChangePage={this.handleChangePage}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>                
            }
            </div>
        );
    }
}

export default Home;

import React from 'react';
import '../assets/css/Style.css';
import logo from '../assets/imgs/logo.png';
import { Button, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, withStyles } from '@material-ui/core';
import md5 from 'md5';
import axios from 'axios';
import Logo from '../components/logo';
import Paper from '@material-ui/core/Paper';

//const html_path = 'http://localhost:3000/';
//const php_path = 'http://localhost/promocaosorteio/';
const html_path = 'https://unimedsobral.epizy.com/';
const php_path = 'https://unimedsobral.epizy.com/api/';

const StyledButton = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(220, 255, 173, 1)',
        '&:hover': {
            backgroundColor: 'rgb(186, 255, 96, 1)',
        }
    },
}))(Button);

const StyledTextField = withStyles((theme) => ({
    root: {
        backgroundColor: '#F,FF',//'rgb(220, 255, 173, 1)',
        marginBottom: '15px'
    },
}))(TextField);

const cpfMask = (value) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2') 
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

const cnpjMask = (value) => {
    return value
    .replace(/\D/g, '') 
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1/$2')
        .replace(/(\/\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

class Cadastro extends React.Component {    
    
    constructor(props) {
        super(props);
        this.state = {
            auth: false,
            nome: '',
            cpf: '',
            email: '',
            telefone: '',
            is_cpf: true,
        }
    }

    mascara = (value, is_cpf) => {
        if (is_cpf) {
            return cpfMask(value);
        } else {
            return cnpjMask(value);
        }
    }

    validar = (value) => {
        if (this.state.is_cpf) {
            return this.validarCpf(value);
        } else {
            return this.validarCnpj(value);
        }
    }

    validarCpf = (cpfString) => {
        let cpf = cpfString;
        let soma = 0;
        let cont = 10;
        let d1 = 0;
        let d2 = 0;
        cpf = cpf.replace(/\./g, '').replace(/\-/g, '');
        cpf = cpf.split('')

        for (let i = 0; i < 9; i++) {
            soma += parseInt(cpf[i]) * cont--;
        }

        d1 = (soma * 10) % 11;
        d1 = d1 === 10 ? 0 : d1;
        
        soma = 0;
        cont = 11;
        for (let i = 0; i < 10; i++) {
            soma += parseInt(cpf[i]) * cont--;
        }

        d2 = (soma * 10) % 11;
        d2 = d2 === 10 ? 0 : d2;
        if (d1 === parseInt(cpf[9]) && d2 === parseInt(cpf[10])) {
            return true;
        } else {
            return false;
        }
    }

    validarCnpj = (cnpjString) => {
        let cnpj = cnpjString;
        let soma = 0;
        let cont = 2;
        let d1 = 0;
        let d2 = 0;
        let nums = cnpj.replace(/\./g, '').replace(/\//g, '').replace(/\-/g, '');
        nums = nums.split('');

        for (let i = nums.length - 3; i >= 0; i--) {
            soma += parseInt(nums[i]) * cont++;
            cont = cont > 9 ? 2 : cont;
        }
        d1 = (soma * 10) % 11;
        d1 = d1 === 10 ? 0 : d1;
        
        soma = 0;
        cont = 2;
        for (let i = nums.length - 2; i >= 0; i--) {
            soma += parseInt(nums[i]) * cont++;
            cont = cont > 9 ? 2 : cont;
        }

        d2 = (soma * 10) % 11;
        d2 = d2 === 10 ? 0 : d2;
        if (d1 === parseInt(cnpj[cnpj.length-2]) && d2 === parseInt(cnpj[cnpj.length-1])) {
            return true;
        } else {
            return false;
        }
    }

    enviarCadastro() {
        if (!this.validar(this.state.cpf)) {
            alert("CPF/CNPJ inválido!");
        } else {
            let formData = new FormData();
            formData.append('nome', this.state.nome);
            formData.append('cpf', this.state.cpf.replace(/\./g, '').replace(/\//g, '').replace(/\-/g, ''));
            formData.append('email', this.state.email);
            formData.append('telefone', this.state.telefone);
            formData.append('id_vendedor', localStorage.getItem('@uniPromo/id_vendedor'));
            axios({
                method: 'post',
                url: php_path + 'cadastrar.php',
                data: formData,        
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function (response) {
                console.log(response)
                if (response.data[0] === false) {
                    window.location = html_path + 'visualizarCadastro?id=' + response.data[1];
                } else {
                    switch (response.data[0]) {
                        case 10:
                            alert("Este usuário já está cadastrado!");
                            window.location = html_path + 'home?busca=' + formData.get('cpf');
                            break;
                        case 11:
                            alert("Este usuário já está cadastrado! Você será redirecionado para a tela de informações.");
                            window.location = html_path + 'visualizarCadastro?id=' + response.data[1];
                            break;
                        default:
                            alert("Erro desconhecido! Código: " + response.data[0]);
                            break;
                    }
                }
            })
            .catch(function (response) {
                console.log('Erro: ' + response);
            })
        }
    }

    componentDidMount(){
        let id_vendedor = localStorage.getItem('@uniPromo/id_vendedor') ?? false;
        let data = localStorage.getItem('@uniPromo/data') ?? false;
        let token = localStorage.getItem('@uniPromo/token') ?? false;
        if (!id_vendedor || !data || !token || md5(id_vendedor + '-' + data) !== token) {
           window.location = html_path + 'login';
        } else {
            this.setState({ auth: true })
        }
    }

    render(){
        return (
            <div className="gridParent" align="center">
            {this.state.auth &&
            <Grid container justify="center">
                <Logo/>
                
                    <Grid container item xs={12} justify="center">
                        <Paper style={{textAlign:'center', padding: '10px'}}>
                                <br/>
                                <Typography align="center">
                                    Insira as informações do cliente
                                </Typography><br/>
                                <Typography align="left">
                                    Nome completo
                                </Typography>
                                <StyledTextField variant="outlined" size="small" placeholder="Nome" fullWidth={true} value={this.state.nome}
                                    onChange={(e) => {this.setState({ nome: e.target.value})}}
                                />
                                <RadioGroup row aria-label="position" name="position" defaultValue="cpf" >
                                    <FormControlLabel value="cpf" control={<Radio color="primary" />} label="CPF" labelPlacement="start"
                                        onClick={() => {
                                            this.setState({is_cpf: true, cpf: this.mascara(this.state.cpf, true)})
                                        }}
                                    />
                                    <FormControlLabel value="cpnj" control={<Radio color="primary" />} label="CNPJ" labelPlacement="start"
                                        onClick={() => {
                                            this.setState({is_cpf: false, cpf: this.mascara(this.state.cpf, false)})
                                        }}
                                    />
                                </RadioGroup>
                                <StyledTextField variant="outlined" size="small" placeholder={this.state.is_cpf ? "000.000.000-00" : "00.000.000/0000-00"} fullWidth={true} value={this.state.cpf}
                                    onChange={(e) => {this.setState({ cpf: this.mascara(e.target.value, this.state.is_cpf)})}}
                                />
                                <Typography align="left">
                                    Email
                                </Typography>
                                <StyledTextField variant="outlined" size="small" placeholder="email@dominio.com.br" fullWidth={true} value={this.state.email}
                                    onChange={(e) => {this.setState({ email: e.target.value})}}
                                />
                                <Typography align="left">
                                    Telefone
                                </Typography>
                                <StyledTextField variant="outlined" size="small" placeholder="(00) 00000-0000" fullWidth={true} value={this.state.telefone}
                                    onChange={(e) => {this.setState({ telefone: e.target.value})}}
                                />                                                            
                                <Button className="btn" onClick={() => {this.enviarCadastro()}}>Cadastrar</Button>
                                
                            </Paper>
                    </Grid>

            </Grid>                

            }
            </div>
        );
    }
}

export default Cadastro;

import React from 'react';
import '../assets/css/Style.css';
import logo from '../assets/imgs/logo.png';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';

//const html_path = 'http://localhost:3000/';
const html_path = 'https://unimedsobral.epizy.com/';

const StyledButton = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(220, 255, 173, 1)',
        '&:hover': {
            backgroundColor: 'rgb(186, 255, 96, 1)',
        }
    },
}))(Button);

class Logo extends React.Component {    
    
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render(){
        return (
            <Grid className="gridLogo" container item xs={12} justify="center">                
                    <Grid item xs={9} sm={2}>
                        <Typography align="center">
                            <img src={logo} alt='' style={{paddingTop: '10px'}} height="80px" onClick={() => {window.location = html_path}}/>
                        </Typography>
                    </Grid>                    
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" align="center" style={{color:'#FFF'}}>
                            PROMOÇÃO UNIMED
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={2}>                        
                        <Button color="primary" style={{textDecoration: 'underline'}} onClick={() => {
                                localStorage.clear();
                                window.location = html_path + "login";
                            }}>
                            Sair
                        </Button>                     
                    </Grid>
            </Grid>
            
        );
    }
}

export default Logo;

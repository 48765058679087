import React from 'react';
import '../assets/css/Style.css';
import { Button, Grid, Typography } from '@material-ui/core';
import md5 from 'md5';
import axios from 'axios';
import qs from 'qs';
import Logo from '../components/logo';

//const html_path = 'http://localhost:3000/';
//const php_path = 'http://localhost/promocaosorteio/';
const html_path = 'https://unimedsobral.epizy.com/';
const php_path = 'https://unimedsobral.epizy.com/api/';

const cpfMask = (value) => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

const cnpjMask = (value) => {
    return value
    .replace(/\D/g, '') 
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1/$2')
        .replace(/(\/\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

class VisualizarCadastro extends React.Component {    
    
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            auth: false,
        }
    }

    mascara = (value) => {
        if (value.length === 11) {
            return cpfMask(value);
        } else {
            return cnpjMask(value);
        }
    }

    async componentDidMount(){
        let qs = require('qs');
        let obj = this;
        let id_vendedor = localStorage.getItem('@uniPromo/id_vendedor') ?? false;
        let data = localStorage.getItem('@uniPromo/data') ?? false;
        let token = localStorage.getItem('@uniPromo/token') ?? false;
        let id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id;

        if (!id || !id_vendedor || !data || !token || md5(id_vendedor + '-' + data) !== token) {
            window.location = html_path + 'login';
        } else {
            let formData = new FormData();
            formData.append('id', id);
            await axios({
                method: 'post',
                url: php_path + 'listar.php',
                data: formData,        
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function(response) {
                console.log(response);
                if (response.data[0] === true) {
                    if (response.data[1].adesao !== null) {
                        obj.setState({
                            dados: response.data[1],
                            auth: true
                        });
                    } else {
                        window.location = html_path + 'sorteio?id=' + id;
                    }
                } else {
                    window.location = html_path + 'home';
                }
            })
            .catch(function(response) {
                console.log(response);
            })

        }
    }

    render(){
        return (
            <>
            {this.state.auth &&
            <Grid className="gridParent2" container justify="center">
                <Logo/>
                <Grid className="gridBody" container item xs={12} justify="center">
                    <Grid className="gridInformacoes" container item xs={10} justify="center">
                        <Grid className="gridTitulo" item xs={12}>
                            <Typography variant="h5" align="center">
                                <b>Informações cadastrais</b>
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} sm={6} spacing={2}>
                            <Grid item xs={12}><Typography variant="h6"><b>Cliente</b></Typography></Grid>
                            <Grid item xs={12}><Typography>Nome:</Typography><Typography>{this.state.dados['nome_cliente']} </Typography></Grid>
                            <Grid item xs={12}><Typography>CPF/CNPJ:</Typography><Typography>{this.mascara(this.state.dados['cpf_cliente'])} </Typography></Grid>
                            <Grid item xs={12}><Typography>Email:</Typography><Typography>{this.state.dados['email_cliente']} </Typography></Grid>
                            <Grid item xs={12}><Typography>Telefone:</Typography><Typography>{this.state.dados['telefone_cliente']} </Typography></Grid>
                            <Grid item xs={12}><Typography>Hora e data de cadastro:</Typography><Typography>{this.state.dados['data_criacao']} </Typography></Grid>
                        </Grid>
                        <Grid container item xs={12} sm={6} spacing={2}>
                            <Grid item xs={12}><Typography variant="h6"><b>Vendedor</b></Typography></Grid>
                            <Grid item xs={12}><Typography>Nome: {this.state.dados['nome_vendedor']}</Typography></Grid>
                            <Grid item xs={12}><Typography>Email: {this.state.dados['email_vendedor']}</Typography></Grid>
                            <Grid item xs={12}><Typography variant="h6"><b>Descontos</b></Typography></Grid>
                            <Grid item xs={12}><Typography>Adesão: {this.state.dados['adesao']}%</Typography></Grid>
                            <Grid item xs={12}><Typography>Mensalidades: {this.state.dados['vitalicio']}%</Typography></Grid>                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            }
            </>
        );
    }
}

export default VisualizarCadastro;

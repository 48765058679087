import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Cadastro from './views/cadastro';
import Home from './views/home';
import Login from './views/login';
import VisualizarCadastro from './views/visualizarCadastro';
import Sorteio from './views/sorteio';

const Routes = () => (
  <BrowserRouter basename="">
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/cadastro" component={Cadastro} />
      <Route exact path="/visualizarCadastro" component={VisualizarCadastro} />
      <Route exact path="/sorteio" component={Sorteio} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
import React from 'react';
import '../assets/css/Style.css';
import '../App.css';
import logo from '../assets/imgs/logo.png';
import axios from 'axios';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import md5 from 'md5';
import Logo from '../components/logo';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';

//const html_path = 'http://localhost:3000/';
//const php_path = 'http://localhost/promocaosorteio/';
const html_path = 'https://unimedsobral.epizy.com/';
const php_path = 'https://unimedsobral.epizy.com/api/';

class Login extends React.Component {    
    
    constructor(props) {
        super(props);

        this.state = {
            aviso: false,
            msg: '',
            user: '',
            pass: '',
            auth: false
        }        
    }

    handleKeyPress = (e) => {
        if(e.key === 'Enter'){
          this.fazerLogin();
        }
    }
    
    fazerLogin() {
        let formData = new FormData();
        formData.append('user', this.state.user);
        formData.append('pass', this.state.pass);

        axios({
            method: 'post',
            url: php_path + 'login.php',
            data: formData,        
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response.data[0] === true) {
                this.setState({aviso: true, msg: 'Usuário ou senha incorretos!', user: '', pass: ''});
            } else {
                localStorage.setItem('@uniPromo/id_vendedor', response.data[1]);
                localStorage.setItem('@uniPromo/nome_vendedor', response.data[2]);
                localStorage.setItem('@uniPromo/email_vendedor', response.data[3]);
                localStorage.setItem('@uniPromo/data', response.data[4]);
                localStorage.setItem('@uniPromo/token', response.data[5]);
                window.location = html_path + 'home';
            }
        })
        .catch((response) => {
            this.setState({aviso: true, msg: String(response), user: '', pass: ''})
            console.log(response);
        })
    }

    componentDidMount(){
        //document.body.style.height = "100vh";

        let id_vendedor = localStorage.getItem('@uniPromo/id_vendedor') ?? false;
        let data = localStorage.getItem('@uniPromo/data') ?? false;
        let token = localStorage.getItem('@uniPromo/token') ?? false;
        if (id_vendedor && data && token && md5(id_vendedor + '-' + data) === token) {
            window.location = html_path + 'home';
        } else {
            localStorage.clear();
            this.setState({ auth: true })
        }
    }

    render(){
        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
              return;
            }  
            this.setState({aviso: false});
        };

        return (
            <div className="gridParentLogin">
            {this.state.auth &&
            <Grid  container spacing={1} className='gridLogin'>
                <Grid item xs={12}>
                    <Paper style={{background: '#8EC547'}}>
                        <Grid item xs={12}>
                            <Typography align="center">
                                <img src={logo} alt='' style={{paddingTop: '10px'}} height="80px" />
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{color:'#FFF'}} variant="h6" align="center">
                                PROMOÇÃO UNIMED 2021
                            </Typography>
                        </Grid>
                    </Paper>           
                </Grid>                                         
            
                <Grid item xs={12}>                                            
                    <Paper style={{textAlign:'center', padding: '15px 15px 0 15px'}}>
                    { 
                        this.state.aviso ? 
                        <Alert severity="error" onClose={handleClose}>
                            {this.state.msg}
                        </Alert>
                        :
                        ''
                    }

                        <Typography align="center">
                            LOGIN
                        </Typography><br/>
                        <Typography align="left">
                            Nome do usuário
                        </Typography>
                        <TextField value={this.state.user} onKeyPress = {this.handleKeyPress} variant="outlined" size="small" fullWidth={true}  style={{marginBottom: '10px'}}
                            onChange={(e) => {
                                this.setState({ user: e.target.value});
                            }}
                        />
                        <Typography align="left">
                            Senha
                        </Typography>
                        <TextField value={this.state.pass} onKeyPress = {this.handleKeyPress} variant="outlined" size="small" type="password" fullWidth={true}
                            onChange={(e) => {
                                this.setState({ pass: e.target.value});
                            }}
                        />                        
                        <Typography align="center" style={{marginTop:'20px'}}>
                            <Button className="btn" variant="outlined" onClick={() => {this.fazerLogin()}}>Entrar</Button>
                        </Typography>
                        <br/>
                    </Paper>                        
                </Grid>
            </Grid>
            }
            </div>
        );
    }
}

export default Login;
